<template>
    <div class="col-12 col-lg-9 max-1085">
        <section class="blockElement needHelp">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <h1 class="bold mb-md-4 f-30">{{$t('need_help.text1')}}</h1>
                           <!--  <div class="form-group position-relative mb-0">
                                <input type="text" class="form-control" placeholder="Search here.." name="">
                                <a href="javascript:void(0)" class="searchIcon"><vue-feather type="search"></vue-feather></a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section class="blockElement space pb-0 needQuestion">
                <div class="container">
                    <div class="row tradeManually fiveColumn justify-content-center">
                        <div class="col-12 mb-4 mb-md-5 text-center">
                            <h4>Questions You May Have</h4>
                        </div>
                        <div class="col-12">
                            <ul class="questionList d-flex align-items-center justify-content-center flex-wrap">
                                <li><router-link to="/help-center-questions" class="shadowButton">How does ZuluTrade work?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How can I learn to use the Platform?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How do I get started with Autotrading?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How do I register for a Real Account?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How much does ZuluTrade cost?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How can I check if a Trader is right for me?</router-link></li>
                                <li><router-link to="/help-center-questions" class="shadowButton">How can I communicate with other ZuluTrade Users?</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> -->
            <section class="blockElement needMoreHelp">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help1"><v-lazy-image class="d-block h-70" src="/assets/images/businessman.svg" :alt="$t('need_help.text2')" :title="$t('need_help.text3')" /></span>
                                <h4 class="bold f-18 mb-0"><router-link to="/user-guide">{{$t('need_help.text4')}}</router-link></h4>  
                                <p class="f-13 mb-0 neutralGrey">{{$t('need_help.text5')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help2"><v-lazy-image class="d-block h-70" src="/assets/images/traderGuide.svg" :alt="$t('need_help.text6')" :title="$t('need_help.text7')" /></span>
                                <h4 class="bold f-18 mb-0"><router-link to="/leader-guide">{{$t('need_help.text8')}}</router-link></h4>  
                                <p class="f-13 mb-0 neutralGrey">{{$t('need_help.text9')}}</p>     
                            </div> 
                        </div>
                        <!-- <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help3"><v-lazy-image class="d-block h-70" src="/assets/images/affiliate-marketing.svg" :alt="$t('need_help.text10')" :title="$t('need_help.text11')" /></span>
                                <h4 class="bold f-18 mb-0"><router-link to="/affiliate-guide">{{$t('need_help.text10')}}</router-link></h4>  
                                <p class="f-13 mb-0 neutralGrey">{{$t('need_help.text11')}}</p>  
                            </div> 
                        </div> -->
                    </div>
                </div>
            </section>
            <section class="blockElement needMoreHelp">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help2">
                                    <v-lazy-image class="d-block h-70" src="/assets/images/needChat.svg" alt="Live chat" title="You can chat with our Support in all languages">
                                </span>
                                <h4 class="bold f-18 mb-0"><a href="javascript:void(0)">Live chat</a></h4>  
                                <p class="f-13 mb-0 neutralGrey">You can chat with our Support in all languages</p>     
                            </div> 
                        </div> -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help3">
                                    <v-lazy-image class="d-block h-70" src="/assets/images/emailHelp.svg" :alt="$t('need_help.text6')" :title="$t('need_help.text12')" />
                                </span>
                                <h4 class="bold f-18 mb-0"><a href="mailto:support@zulutrade.com">{{$t('need_help.text13')}}</a></h4>  
                                <p class="f-13 mb-0 neutralGrey">{{$t('need_help.text14')}}</p>     
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body h-100 bg-white">
                                <span class="d-flex align-items-center justify-content-center mb-3 need help1">
                                    <v-lazy-image class="d-block h-70" src="/assets/images/phonecallhelp.svg" :alt="$t('need_help.text10')" :title="$t('need_help.text11')" />
                                </span>
                                <h4 class="bold f-18 mb-0"><a href="tel:+30 213 0176 399">{{$t('need_help.text15')}}</a></h4>  
                                <p class="f-13 mb-0 neutralGrey">{{$t('need_help.text16')}}</p>  
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section class="blockElement space moreHelp">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-4 mb-md-4 text-center">
                            <h2>User Support</h2>
                            <p>View the comprehensive support per each user profile</p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body minInherit bg-white p-3">
                                <a href="tel:+30 213 0176 399" class="medium secondary d-flex align-items-center justify-content-center"><i class="f-20 me-2 fa fa-calendar" aria-hidden="true"></i> Schedule a call</a>   
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body minInherit bg-white p-3">
                                <a href="mailto:feedback@zulutrade.com" target="_blank" class="medium secondary d-flex align-items-center justify-content-center"><i class="f-20 me-2 fa fa-commenting-o" aria-hidden="true"></i> Give Us Your Feedback</a>   
                            </div> 
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card-body minInherit bg-white p-3">
                                <a href="mailto:complaints@zulutrade.com" target="_blank" class="medium secondary d-flex align-items-center justify-content-center"><i class="f-20 me-2 fa fa-frown-o" aria-hidden="true"></i> Make a Complaint</a>   
                            </div> 
                        </div>
                    </div>
                </div>
            </section> -->
    </div>
</template>
